import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuard } from "./shared/auth.guard";
import { PrivacyComponent } from './config/privacy/privacy.component';
import { AboutcompanyComponent } from "./config/aboutcompany/aboutcompany.component";

import { AboutusComponent } from './config/aboutus/aboutus.component';
import { TermsComponent } from './config/terms/terms.component';
import {ContactusComponent} from './config/contactus/contactus.component';
export const AppRoutes: Routes = [{
        path: '',
        redirectTo: 'pages',
        pathMatch: 'full',
      },{
        path: '',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        children: [{
            path: '',
            loadChildren: './dashboard/dashboard.module#DashboardModule'
        },
        {
            path: 'users',
            loadChildren: './users/users.module#UsersModule'
        },
        {
            path: 'jobs',
            loadChildren: './alljobs/alljobs.module#AlljobsModule'
        },
        {
            path: 'settings',
            loadChildren: './settings/settings.module#SettingsModule'
        },
        {
            path: 'config',
            loadChildren: './config/config.module#ConfigModule'
        }
    ]
        },{
            path: '',
            component: AuthLayoutComponent,
            children: [{
                path: 'pages',
                loadChildren: './pages/pages.module#PagesModule'
            }]
        },
        {
            path: 'privacy',
            component: PrivacyComponent
        },
        {
            path: 'aboutus',
            component: AboutusComponent
        },
        {
            path: 'terms',
            component: TermsComponent
        },
        {
            path:'contactus',
            component:ContactusComponent
        }
];




