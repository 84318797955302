import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { AuthInterceptor } from './shared/authconfig.interceptor';
import { DataTablesModule } from 'angular-datatables';
import { AppComponent }   from './app.component';
import { EditorModule } from "@tinymce/tinymce-angular";


import { SidebarModule } from './sidebar/sidebar.module';
import { FixedPluginModule } from './shared/fixedplugin/fixedplugin.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AppRoutes } from './app.routing';

import { JobsService } from "./services/jobs.service";
import { AuthService } from "./services/auth.service";


import { AuthGuard } from './shared/auth.guard';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { CreatecontactusComponent } from './config/createcontactus/createcontactus.component';



@NgModule({
    imports:      [
        BrowserAnimationsModule,
        DataTablesModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(AppRoutes,{
          useHash: true
        }),
        NgbModule,
        HttpModule,
        SidebarModule,
        NavbarModule,
        FooterModule,
        FixedPluginModule,
        HttpClientModule,
        BrowserModule,
        DateInputsModule,
        EditorModule
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
        CreatecontactusComponent,
    ],
    providers: [
        JobsService,
        AuthGuard,
        AuthService,
        AuthInterceptor,
        {
            provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
        }
    ],
    bootstrap:    [ AppComponent ]
})

export class AppModule { }

