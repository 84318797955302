import { Component, OnInit, AfterViewInit, AfterViewChecked, AfterContentInit } from '@angular/core';

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    collapse?: string;
    icontype: string;
    // icon: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [{
        path: '/dashboard',
        title: 'Dashboard',
        type: 'link',
        icontype: 'nc-icon nc-bank'
    },
    // {
    //     path: '/components',
    //     title: 'Components',
    //     type: 'sub',
    //     collapse: 'components',
    //     icontype: 'nc-icon nc-layout-11',
    //     children: [
    //         {path: 'buttons', title: 'Buttons', ab:'B'},
    //         {path: 'grid', title: 'Grid System', ab:'GS'},
    //         {path: 'panels', title: 'Panels', ab:'P'},
    //         {path: 'sweet-alert', title: 'Sweet Alert', ab:'SA'},
    //         {path: 'notifications', title: 'Notifications', ab:'N'},
    //         {path: 'icons', title: 'Icons', ab:'I'},
    //         {path: 'typography', title: 'Typography', ab:'T'}
    //     ]
    // },
    // {
    //     path: '/forms',
    //     title: 'Forms',
    //     type: 'sub',
    //     collapse: 'forms',
    //     icontype: 'nc-icon nc-ruler-pencil',
    //     children: [
    //         {path: 'regular', title: 'Regular Forms', ab:'RF'},
    //         {path: 'extended', title: 'Extended Forms', ab:'EF'},
    //         {path: 'validation', title: 'Validation Forms', ab:'VF'},
    //         {path: 'wizard', title: 'Wizard', ab:'W'}
    //     ]
    // },
    // {
    //     path: '/tables',
    //     title: 'Tables',
    //     type: 'sub',
    //     collapse: 'tables',
    //     icontype: 'nc-icon nc-single-copy-04',
    //     children: [
    //         {path: 'regular', title: 'Regular Tables', ab:'RT'},
    //         {path: 'extended', title: 'Extended Tables', ab:'ET'},
    //         {path: 'datatables.net', title: 'Datatables.net', ab:'DT'}
    //     ]
    // },
    // {
    //     path: '/maps',
    //     title: 'Maps',
    //     type: 'sub',
    //     collapse: 'maps',
    //     icontype: 'nc-icon nc-pin-3',
    //     children: [
    //         {path: 'google', title: 'Google Maps', ab:'GM'},
    //         {path: 'fullscreen', title: 'Full Screen Map', ab:'FSM'},
    //         {path: 'vector', title: 'Vector Map', ab:'VM'}
    //     ]
    // },
    // {
    //     path: '/widgets',
    //     title: 'Widgets',
    //     type: 'link',
    //     icontype: 'nc-icon nc-box'

    // },
    {
        path: '/users',
        title: 'users',
        collapse: 'users',
        type: 'sub',
        icontype: 'nc-icon nc-circle-10',
        children: [
            {path: 'freelancers', title: 'freelancers', ab:'fl'},
            {path: 'clients', title: 'clients', ab:'cl'}
        ]
    },
    {
        path: '/jobs',
        title: 'jobs',
        type: 'link',
        collapse: 'tables',
        icontype: 'nc-icon nc-briefcase-24'

    },
    // {
    //     path: '/allskills',
    //     title: 'skills',
    //     type: 'link',
    //     collapse: 'tables',
    //     icontype: 'nc-icon nc-single-copy-04'

    // },
        {
            path: '/settings',
            title: 'settings',
            collapse: 'settings',
            type: 'sub',
            icontype: 'nc-icon nc-settings-gear-65',
            children: [
                {path: 'skills', title: 'skills', ab:'SL'},
                {path: 'countries', title: 'countries', ab:'CN'},
                // {path: 'states', title: 'states', ab:'SE'},
                {path: 'cities', title: 'cities', ab:'CY'},
                {path: 'categories', title: 'categories', ab:'CG'},
                // {path: 'jobtypes', title: 'Job Types', ab:'JT'},
                {path: 'subtopics', title: 'subtopics', ab:'ST'}
            ]
        },
        {
            path: '/config',
            title: 'config',
            collapse: 'config',
            type: 'sub',
            icontype: 'nc-icon nc-palette',
            children: [
                {path: 'privacy', title: 'Privacy', ab:'PC'},
                {path: 'terms', title: 'Terms', ab:'TMS'},
                {path: 'aboutcompany', title: 'About Us', ab:'AC'},
                {path: 'contactus', title: 'Contact Us', ab:'CU'}
            ]
        },
    // {
    //     path: '/charts',
    //     title: 'Charts',
    //     type: 'link',
    //     icontype: 'nc-icon nc-chart-bar-32'

    // },{
    //     path: '/calendar',
    //     title: 'Calendar',
    //     type: 'link',
    //     icontype: 'nc-icon nc-calendar-60'
    // },{
    //     path: '/pages',
    //     title: 'Pages',
    //     collapse: 'pages',
    //     type: 'sub',
    //     icontype: 'nc-icon nc-book-bookmark',
    //     children: [
    //         {path: 'timeline', title: 'Timeline Page', ab:'T'},
    //         {path: 'user', title: 'User Page', ab:'UP'},
    //         {path: 'login', title: 'Login Page', ab:'LP'},
    //         {path: 'register', title: 'Register Page', ab:'RP'},
    //         {path: 'lock', title: 'Lock Screen Page', ab:'LSP'}
    //     ]
    // }
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent {
    public menuItems: any[];
    isNotMobileMenu(){
        if( window.outerWidth > 991){
            return false;
        }
        return true;
    }

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
    ngAfterViewInit(){
    }
}
