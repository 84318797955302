import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class JobsService {

  constructor(private http:HttpClient) { }

  getjobdetails(job_id: any) {
    const body = { job_id };
    return this.http.post<any>(environment.apiUrl + `/api/jobPosts/client/job_details`, body);
}

  getalljobs(): Observable<any> {
    return this.http.get(environment.apiUrl + "/api/admin/alljobs");
  }

  getJobById(job_post_id) {
    return this.http.get(environment.apiUrl + `/api/admin/alljobs/${job_post_id}`);
  }

  updatejob(job:any) {
    return this.http.post(environment.apiUrl + `/api/admin/updateJob`, job);
  }

  addJob(data:any) {
    return this.http.post<any>(environment.apiUrl + `/api/jobPosts/client/create_jobPost`, data);
  }

  

}








