import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AllService } from 'app/services/all.service';


@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {
  aboutusData: any;
  showCreate: boolean = false;

  constructor(private allService: AllService, private router: Router) { }

  ngOnInit(): void {
    this.allService.getContentList().subscribe(
      (response) => {
        var foundValue = response.data.filter(obj => obj.name == 'aboutus');
        this.allService.getContent(foundValue[0].id).subscribe(
          (response) => {
            this.aboutusData = response.data.data;
          });
      });
    
      if (this.router.url.indexOf('/config/aboutcompany') > -1) {
        this.showCreate = true;
      }
  }
}
