import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AllService } from 'app/services/all.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  termsData: any;
  showCreate: boolean = false;

  constructor(private allService: AllService, private router: Router) { }

  ngOnInit(): void {
    this.allService.getContentList().subscribe(
      (response) => {
        var foundValue = response.data.filter(obj => obj.name == 'terms');
        this.allService.getContent(foundValue[0].id).subscribe(
          (response) => {
            this.termsData = response.data.data;
          });
      });
    
      if (this.router.url.indexOf('/config/terms') > -1) {
        this.showCreate = true;
      }
  }

}
