import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AllService {

  constructor(private http:HttpClient) { }

  getallcountries(): Observable<any> {
    return this.http.get(environment.apiUrl + "/api/public/country");
  }
  getallstates(country_id): Observable<any> {
    return this.http.get(environment.apiUrl + "/api/public/state/" + country_id);
  }
  getallcategories(): Observable<any> {
    return this.http.get(environment.apiUrl + "/api/public/job/category");
  }
  
  getallgenders(): Observable<any> {
    return this.http.get(environment.apiUrl + "/api/public/public_types");
  }
  getalljobtypes(): Observable<any> {
    return this.http.get(environment.apiUrl + "/api/public/public_types");
  }



  getallcities(state_id): Observable<any> {
    return this.http.get(environment.apiUrl + "/api/public/city/" + state_id);
  }


  getUserslistById(user_type_id) {
    const body = { user_type_id };
    return this.http.post<any>(environment.apiUrl + `/api/admin/users_list`, body);
  }

  createCategory(image, job_category_code, job_category): Observable<any> {
    const category = {
      image: image,
      job_category_code: job_category_code,
      job_category: job_category
    };
    
    return this.http.post("http://sampleapp-env.eba-xbpbgrv3.ap-southeast-2.elasticbeanstalk.com/api/public/add/category", category);
  }

  createCountry(country_name, country_code): Observable<any> {
    const country = {
      country_name: country_name,
      country_code: country_code
    };
    return this.http.post("http://sampleapp-env.eba-xbpbgrv3.ap-southeast-2.elasticbeanstalk.com/api/admin/country/add", country);
  }

  createCity(city_name, city_code, country_id): Observable<any> {
    const city = {
      city_name: city_name,
      city_code: city_code,
      state_id: country_id
    };
    return this.http.post(environment.apiUrl + "/api/admin/city/add", city);
  }

  createState(state_name, state_code, country_id): Observable<any> {
    const state = {
      state_name: state_name,
      state_code: state_code,
      country_id: country_id

    };
    return this.http.post("http://sampleapp-env.eba-xbpbgrv3.ap-southeast-2.elasticbeanstalk.com/api/admin/state/add", state);
  }

  editCategory(data: any) {
    return this.http.post( environment.apiUrl + `/api/public/update/category`, data);
  }

  editCity(data: any) {
    return this.http.post( environment.apiUrl + `/api/admin/city/edit`, data);
  }

  editCountry(data: any) {
    return this.http.post( environment.apiUrl + `/api/admin/country/edit`, data);
  }

  editState(data: any) {
    return this.http.post( environment.apiUrl + `/api/admin/state/edit`, data);
  }




  editFreelancerUser(data: any) {
    return this.http.post( environment.apiUrl + `/api/admin/updateUser`, data);
  }


  createFreelancer(firstName, lastName, email, password, dob, gender_id, city_id, county_id, mobile_no): Observable<any> {
    const freelancer = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      dob: dob,
      gender_id: gender_id,
      city_id: city_id,
      county_id: county_id,
      mobile_no: mobile_no
    };
    return this.http.post(environment.apiUrl + "/api/auth/register/2", freelancer);
  }

  createClient(firstName, lastName, email, password, dob, gender_id, city_id, county_id, mobile_no): Observable<any> {
    const client = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      dob: dob,
      gender_id: gender_id,
      city_id: city_id,
      county_id: county_id,
      mobile_no: mobile_no

    };
    return this.http.post("http://sampleapp-env.eba-xbpbgrv3.ap-southeast-2.elasticbeanstalk.com/api/auth/register/3", client);
  }

  deleteFreelancer(data: any ): Observable<any> {
    const body = { 
      user_id: (data.user_id).toString()
    };
    return this.http.post(environment.apiUrl + `/api/admin/deleteUser`, body);
  }

  deleteFreelancer1(user_id: any ): Observable<any> {
    const body = { 
      user_id
    };
    return this.http.post(environment.apiUrl + `/api/admin/deleteUser`, body);
  }

  deleteJob(data: any ): Observable<any> {
    const body = { 
      client_job_post_id: (data.client_job_post_id).toString()
    };
    return this.http.post(environment.apiUrl + `/api/admin/deleteJob`, body);
  }

  
  deleteSkill(data: any ): Observable<any> {
    const body = { 
      
      skill_id: (data.taxonomy_id).toString()
    };
    return this.http.post(environment.apiUrl + `/api/admin/deleteSkill`, body);
  }

  deleteCountry(data: any ): Observable<any> {
    const body = { 
      country_id: (data.country_id).toString()
    };
    return this.http.post(environment.apiUrl + `/api/admin/country/remove`, body);
  }

  deleteState(data: any ): Observable<any> {
    const body = { 
      state_id: (data.state_id).toString()
    };
    return this.http.post(environment.apiUrl + `/api/admin/state/remove`, body);
  }

  deleteCity(data: any ): Observable<any> {
    const body = { 
      city_id: (data.city_id).toString()
    };
    return this.http.post(environment.apiUrl + `/api/admin/city/remove`, body);
  }

  deleteCategory(data: any ): Observable<any> {
    const body = { 
      job_category_id: (data.job_category_id).toString()
    };
    return this.http.post(environment.apiUrl + `/api/public/change_status/category`, body);
  }
  

  createckData(data, name): Observable<any> {
    const postData = {     
      data: data,
      name: name
    };

    return this.http.post( environment.apiUrl + "/api/public/settings/add", postData);
  }

  getContent(id): Observable<any> {
    return this.http.get(environment.apiUrl + "/api/public/settings/list/" + id);
  }

  getContentList(): Observable<any> {
    return this.http.get(environment.apiUrl + "/api/public/settings/list");
  }

  updateContent(id, name, data): Observable<any> {
    const putData = {     
      id: id,
      name: name,
      data: data
    };
    return this.http.put(environment.apiUrl + "/api/public/settings/update", putData);
  }
}


   
























